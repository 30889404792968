import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClientService } from '../http-client.service';
import { IAService } from '../local/ia.service';
import { UserData } from '../local/user-data';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor( public http: HttpClientService,
    public userData: UserData,
    public IA: IAService) { }

  async login(data: {
    user: string,
    pass: string
  }) {

    let url = `${ environment.apiHost }login`;

    return this.http.post(url, data)
    .then((result: any) => {
      if (!(result)) {
        return { };
      }
      setTimeout(() => {
        this.IA.dismissLoader();
      }, 500);

      if(result && result.accessToken && result.userData) {
        this.userData.accessToken = result.accessToken;
        this.userData.user = result.userData;
      }
      return result;
    })
    .catch((err) => {
      setTimeout(() => {
        this.IA.dismissLoader();
      }, 500);
      return [];
    });
  }

  async logout() {
    let url = `${ environment.apiHost }logout`;

    if(!this.userData.isLoggedIn()) {
      return Promise.resolve({ status: true });
    }
    return this.http.post(url, {})
    .then((result: any) => {
      if (!(result)) {
        return { };
      }
      setTimeout(() => {
        this.IA.dismissLoader();
      }, 500);

      if(result) {
        this.userData.accessToken = null;
        this.userData.user = null;
      }
      return result;
    })
    .catch((err) => {
      setTimeout(() => {
        this.IA.dismissLoader();
      }, 500);
      return [];
    });
  }

  async register(data:  { 
    Name: string,
    LastName: string,
    Phone: string,
    BirthDay: string,
    Email: string,
    Password: string
  }) {
    let url = `${ environment.apiHost }clients/add`;

    return this.http.post(url, data)
    .then((result: any) => {
      if (!(result)) {
        return { };
      }
      setTimeout(() => {
        this.IA.dismissLoader();
      }, 500);

      return result;
    })
    .catch((err) => {
      setTimeout(() => {
        this.IA.dismissLoader();
      }, 500);
      return [];
    });
  }

  async profile() {
    let url = `${ environment.apiHost }clients/profile`;

    return this.http.get(url)
    .then((result: any) => {
      if (!(result)) {
        return { };
      }
      setTimeout(() => {
        this.IA.dismissLoader();
      }, 500);

      if(result && result.status && result.info) {
        this.userData.user = result.info;
      }

      return result;
    })
    .catch((err) => {
      setTimeout(() => {
        this.IA.dismissLoader();
      }, 500);
      return [];
    });
  }

  async requestPasswordRecovery(data: any) {
    let url = `${ environment.apiHost }clients/recover`;

    return this.http.post(url, data)
    .then((result: any) => {
      if (!(result)) {
        return { };
      }
      setTimeout(() => {
        this.IA.dismissLoader();
      }, 500);

      
      return result;
    })
    .catch((err) => {
      setTimeout(() => {
        this.IA.dismissLoader();
      }, 500);
      return [];
    });
  }

  async getTerms() {

    let url = `${ environment.apiHost }termsandconditions`;

    return this.http.get(url)
    .then((result: any) => {
      if (!(result)) {
        return { };
      }
      setTimeout(() => {
        this.IA.dismissLoader();
      }, 500);

      return result;
    })
    .catch((err) => {
      setTimeout(() => {
        this.IA.dismissLoader();
      }, 500);
      return [];
    });
  }

  async memeberships() {
    let url = `${ environment.apiHost }membership/list`;

    return this.http.get(url)
    .then((result: any) => {
      if (!(result)) {
        return { };
      }
      setTimeout(() => {
        this.IA.dismissLoader();
      }, 500);

      return result;
    })
    .catch((err) => {
      setTimeout(() => {
        this.IA.dismissLoader();
      }, 500);
      return [];
    });
  }

  async payMemebership(data: any) {

    let url = `${ environment.apiHost }clients/addpayment`;

    return this.http.post(url, data)
    .then((result: any) => {
      if (!(result)) {
        return { };
      }
      setTimeout(() => {
        this.IA.dismissLoader();
      }, 500);

      
      return result;
    })
    .catch((err) => {
      setTimeout(() => {
        this.IA.dismissLoader();
      }, 500);
      return [];
    });
  }

  async saveProfile(data: any) {
    let url = `${ environment.apiHost }clients/update`;

    return this.http.post(url, data)
    .then((result: any) => {
      if (!(result)) {
        return { };
      }
      setTimeout(() => {
        this.IA.dismissLoader();
      }, 500);

      
      return result;
    })
    .catch((err) => {
      setTimeout(() => {
        this.IA.dismissLoader();
      }, 500);
      return [];
    });
  }
}
