import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
//import { UserData } from './local/user-data.service';
import { NavController } from '@ionic/angular';
import { IAService } from './local/ia.service';
import { UserData } from './local/user-data';

@Injectable({
  providedIn: 'root'
})
export class HttpClientService {

  
  

  constructor( private http: HttpClient,
    private navCtrl: NavController,
    private IA: IAService,
    private userData: UserData
     ) { }

  showErrorMessage(err: any) {

    let message = "Internet connection error";

    if(err.message) {
      message = err.message;
    }

    this.IA.showToast(message);
  }

  post(url: string, body: any, typeHeaders: any = 1) {
    return new Promise((resolve) => {

      let headers = new HttpHeaders();
      if(typeHeaders) {
        headers = this.createAuthorizationHeader(headers);
      }
      //console.log(url, body);
      this.http.post(url, body, { headers })
      .subscribe(data => {
        //console.log("Response Data", url, data);
        this.processError(data);

        return resolve(data);
      }, err => {
        //console.log("Error Datra",err);
        if(err.status == 401) {
          return resolve(false);
        } else {
          this.showErrorMessage(err);
        }
        

        return resolve(err);
      });

    });

  }

  get(url: string, typeHeaders: any = 1) {
    return new Promise((resolve) => {
      let headers = new HttpHeaders();
      if(typeHeaders) {
        headers = this.createAuthorizationHeader(headers);
      }

      //console.log(url, body);
      return this.http.get(url, { headers })
      .subscribe(data => {
        //console.log("Response Data GET", url, data);
        this.processError(data);
        return resolve(data);
      }, err => {
        //console.log("Error Datra",err);
        if(err.status == 401) {
          return resolve(false);
        } else {
          this.showErrorMessage(err);
        }
        return resolve(err);
      });
    });
  }


  createAuthorizationHeader(headers: HttpHeaders) {

    if(this.userData.isLoggedIn() && this.userData.accessToken) {
      headers = headers.append('access-token', this.userData.accessToken);

    } else {
      headers = headers.append('access-token', environment.publicToken);

    }
    /*if (this.userData.token) {

        
      headers = headers.append('SF-UserToken', this.userData.token);

    }*/

    return headers;
  }

  

  processError(data: any) {

    if(!data.status && data.message && data.message.info == "Token invalido") {
    //console.log("DATA ERROR", data);
      if(this.userData.isLoggedIn()) {
        this.userData.accessToken = null;
        this.userData.user = null;
      }
    }

  }
}

