import { Component } from '@angular/core';
import { PushService } from './services/push/push.service';
import { Deeplinks } from '@ionic-native/deeplinks/ngx';
import { Platform } from '@ionic/angular';
import { IAService } from './services/local/ia.service';
import { AdmobService } from './services/admob/admob.service';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public appPages = [
    { title: 'Inbox', url: '/folder/Inbox', icon: 'mail' },
    { title: 'Outbox', url: '/folder/Outbox', icon: 'paper-plane' },
    { title: 'Favorites', url: '/folder/Favorites', icon: 'heart' },
    { title: 'Archived', url: '/folder/Archived', icon: 'archive' },
    { title: 'Trash', url: '/folder/Trash', icon: 'trash' },
    { title: 'Spam', url: '/folder/Spam', icon: 'warning' },
  ];
  public labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];
  constructor(public push: PushService,
    public platform: Platform,
    public adMobService: AdmobService,
    public IA: IAService,
    private deeplinks: Deeplinks) {

    }

  ngOnInit() {

    this.platform.ready().then(() => {
      this.setupDeepLinks();

      //setTimeout(() => {
        this.push.init();
      //}, 3 * 1000);
      setTimeout(() => {
        this.adMobService.init();
      }, 1 * 1000);

    });
  }

  

  async processLink(data: any) {
    let url = null;
    if(typeof data === 'string') {
      url = data;
    } else if(data.url) {
      url = data.url;
    }

    if(url) {
      
      url = url.replace('https://kilocentrovidasaludable.com/', '');
      url = url.replace('app/', '');

      if(url) {
        let info = url.split("/");

        let redirectData: any = {
          resource_type: info[0]
        };


        if(info && info[1]) {
          redirectData.resource_id = info[1];
        }
        this.push.pushOpenPage(redirectData);
      }

    } 

  }

  setupDeepLinks() {
    if(this.platform.is('cordova')) {
      this.deeplinks.route({
        '/:item': 'article-details'
      }).subscribe(match => {
        //alert(match.$route)
        //alert( match.$args )
        //alert( match.$link )
        // match.$route - the route we matched, which is the matched entry from the arguments to route()
        // match.$args - the args passed in the link
        // match.$link - the full link data
        //console.log('Successfully matched route', match);

        //alert('MATCH: '+JSON.stringify(match))
        this.processLink(match.$link);
        //this.IA.showToast('MATCH: '+JSON.stringify(match));
        this.setupDeepLinks();
      }, nomatch => {
        //alert('HEELLLO'+ nomatch);
        // nomatch.$link - the full link data
        console.error('Got a deeplink that didn\'t match', nomatch);

        this.processLink(nomatch.$link);
        //this.IA.showToast('NO MATCH: '+JSON.stringify(nomatch));
        this.setupDeepLinks();

      });
    }
  }
}
