import { Injectable } from '@angular/core';

import * as _ from 'lodash';

import { Storage } from '@ionic/storage';
import { Router } from '@angular/router';
import { NavController } from '@ionic/angular';


@Injectable({
  providedIn: 'root'
})
export class UserData {
  
    storageKeys = {
      user: '_user',
      accessToken: '_access_token',
      interstitialLastTime: '_interstitial_last_time',
    };

    storageValues: any = {
     
    }

    constructor(public storage: Storage,
      public router: Router,
      public navCtrl: NavController) { }


    validateLogin(data: any) {

      let params = {
        isRoot: data.isRoot ? 1 : 0,
        url: data.url,
        excludePayment: data.excludePayment ? 1 : 0
      };
      if(data.params) {
        params = Object.assign(params, data.params);
      }

      if(!this.isLoggedIn()) {
        
        this.router.navigate([ '/login', params ]);

      } else if(!parseInt(data.excludePayment) && !this.isMemberShipValid()) {

        //alert("Abre Pantalla de PAGO");
        this.router.navigate([ '/payment', params ]);
      } else {
        this.openPageWithData(data);
      }
    }

    openPageWithData(data: any) {

      if(!data.url) {
        this.navCtrl.back();
        return true;
      }

      let url = data.url;
      let params = data.params;
      if(!params) {
        params = {}
      }
      if(parseInt(data.isRoot)) { 
        this.navCtrl.navigateRoot([ url, params]);
      } else {
        this.router.navigate([url, params]);
      }
    }

    isLoggedIn() {

      return this.user && this.user.ID;
    }

    isMemberShipValid() {
      return this.user && this.user.membership;
    }

    set user(data: any) {
      if(data) {
        this.storageValues.user = data;
        this.storage.set(this.storageKeys.user, data);
      } else {
        this.storage.remove(this.storageKeys.user);
        this.storageValues.user = null;
      }
    }

    get user() {
      return this.storageValues.user;
    }

    set interstitialLastTime(data: any) {
      if(data) {
        this.storageValues.interstitialLastTime = data;
        this.storage.set(this.storageKeys.interstitialLastTime, data);
      } else {
        this.storage.remove(this.storageKeys.interstitialLastTime);
        this.storageValues.interstitialLastTime = null;
      }
    }

    get interstitialLastTime() {
      return this.storageValues.interstitialLastTime;
    }


    set accessToken(data: any) {
      if(data) {
        this.storageValues.accessToken = data;
        this.storage.set(this.storageKeys.accessToken, data);
      } else {
        this.storage.remove(this.storageKeys.accessToken);
        this.storageValues.accessToken = null;
      }
    }

    get accessToken() {
      return this.storageValues.accessToken;
    }

    async initkey(key) {

      await this.storage.create();

      let value = await this.storage.get(this.storageKeys[key]); 

      //console.log("INIT KEY", key, value);
      if(value) {
        this.storageValues[key] = value;
      }
    }

    init() {
      for(var i in this.storageKeys) {
        this.initkey(i);
      }

     
    }
}