import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { HttpClientModule } from '@angular/common/http';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { OneSignal } from '@ionic-native/onesignal/ngx';
import { Storage } from '@ionic/storage';
import { UserData } from './services/local/user-data';
import { AuthService } from './services/auth/auth.service';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { Deeplinks } from '@ionic-native/deeplinks/ngx';
import { AdMobFree } from '@ionic-native/admob-free/ngx';

@NgModule({
  declarations: [ AppComponent ],
  entryComponents: [],
  imports: [
    BrowserModule, 
    IonicModule.forRoot(), 
    AppRoutingModule,

    HttpClientModule,
    
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    SocialSharing,
    OneSignal,
    Storage,
    InAppBrowser,
    Deeplinks,
    AdMobFree
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private userData: UserData,
    
    public authWS: AuthService) {

    this.userData.init();

    setTimeout(() => {
      if(this.userData.isLoggedIn()) {
        this.authWS.profile();
      }

    },1 * 1000);
    
  }
}
