// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  appName: 'Dra. Ayudame',
  apiHost: 'http://service.kilocentrovidasaludable.com/',
  publicToken: '6cc44a899cd6ad3bbb0d2ee8d93901274f6d7748',

  onesignalAppID: '00de7fc0-a204-4ef2-a015-47f3d0d38e4e',
  firebaseSenderID: '420687464878'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
