import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { AdMobFree, AdMobFreeBannerConfig, AdMobFreeInterstitialConfig, AdMobFreeRewardVideo } from '@ionic-native/admob-free/ngx';
import { UserData } from '../local/user-data';


@Injectable({
  providedIn: 'root'
})
export class AdmobService {

  constructor(public adMobFree: AdMobFree,
    private router: Router,
    public userData: UserData) { }


  launchBanner() {
    const bannerConfig: AdMobFreeBannerConfig = {
        //id: 'ca-app-pub-3491053311107901/8603757756',
        // add your config here
        // for the sake of this example we will just use the test config
        isTesting: true,
        autoShow: true
      };
      this.adMobFree.banner.config(bannerConfig);

      this.adMobFree.banner.prepare()
      .then(() => {
          //alert('banner');

        // banner Ad is ready
        // if we set autoShow to false, then we will need to call the show method here
      }).catch(e => console.log(e));

      
      
  }

  launchInterstitial() {

    console.log("Launch Interstitial");
    
    
        let interstitialConfig: AdMobFreeInterstitialConfig = {
            //id: 'ca-app-pub-3491053311107901/4081703595',
            isTesting: true, // Remove in production
            autoShow: true
            //id: Your Ad Unit ID goes here
        };
 
        this.adMobFree.interstitial.config(interstitialConfig);
 
        this.adMobFree.interstitial.prepare().then(() => {
            // success
            //alert('interstitial');

        }).catch(e => console.log(e));;
      
    
  }

  init() {

    if(!this.userData.isLoggedIn() || !this.userData.isMemberShipValid()) {

      this.launchBanner();
      this.initInterstitial();
    }
  }
  initInterstitial() {

    this.router.events.subscribe((val) => {
      this.validateInterstitial();
      return val;
    });
    
  }

  validateInterstitial() {

    let time = (new Date()).getTime();
    let timeDiff = (time - this.userData.interstitialLastTime)/1000;
    
    if(!this.userData.interstitialLastTime || timeDiff >= 60) {
      this.userData.interstitialLastTime = time;
      this.launchInterstitial();
    }
  }

  
}
