import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { OneSignal } from '@ionic-native/onesignal/ngx';
import { environment } from '../../../environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class PushService {

  objBannerPath = {
    article: {
      base: '/home',
      detail: '/article-details'
    },
    directory: {
      base: '/medical-directory',
      detail: '/medical-directory-details'
    },
  }

  constructor(private oneSignal: OneSignal, 
    private router:Router) { }

  init() {
    this.oneSignal.startInit(environment.onesignalAppID, environment.firebaseSenderID);

    this.oneSignal.inFocusDisplaying(this.oneSignal.OSInFocusDisplayOption.Notification);

    this.oneSignal.handleNotificationReceived().subscribe((msg:any) => {
      // do something when a notification is opened
      //console.log("PAYLOAD", JSON.stringify(msg.notification.payload))
    });

    this.oneSignal.handleNotificationOpened().subscribe(( msg: any ) => {
      // do something when a notification is opened

      //alert(JSON.stringify(msg.notification));
      if (msg.notification.payload.additionalData) {
          //console.log("PAYLOAD", JSON.stringify(msg.notification.payload.additionalData))

          this.pushOpenPage(msg.notification.payload.additionalData);
      }

    });

    this.oneSignal.endInit();

    /*this.oneSignal.getIds()
    .then((data: any) => {

      return this.auth.sendPushToken(data.pushToken, this.device.uuid, data.userId)
          
    }).then((res: any) => {

      //alert(JSON.stringify(res));
      if(res.status == "success" && res.user && res.user && res.user.id) {
        this.oneSignal.sendTag('client_id', res.user.id);
      }
    });*/
  }

  pushOpenPage(result: any) {

    //alert(JSON.stringify(result));

    if (result.resource_type) {

      if (!result.resource_id) { //Base view (List)
        if (this.objBannerPath[result.resource_type]['base']) {
          this.router.navigate([this.objBannerPath[result.resource_type]['base']]);
        }
      } else { //Detail view
        //console.log("DETAIL", this.objBannerPath[result.resource_type]['detail'], result.resource_id)
        if (this.objBannerPath[result.resource_type]['detail']) {
          this.router.navigate([this.objBannerPath[result.resource_type]['detail'], {
            item: result.resource_id
          }]);
        }
      }

    } else {
      //Default view (Notification view)
      this.router.navigate([this.objBannerPath['notification']['base']]);
    }
  }
}
