import { Injectable } from '@angular/core';
import { LoadingController, ToastController,Platform,ActionSheetController } from '@ionic/angular';

/*
import { FilePath } from '@ionic-native/file-path/ngx';
import { File } from '@ionic-native/file/ngx';
import { Camera, CameraOptions } from '@ionic-native/camera/ngx';
import { Crop } from '@ionic-native/crop/ngx';*/

@Injectable({
  providedIn: 'root'
})
export class IAService {

  loader: any;
  timeout: any;

  constructor(public loadingController: LoadingController,
    private toastCtrl: ToastController,
    //private filePath: FilePath,
    //public file: File,
    //public camera: Camera,
    //public crop: Crop,
    private platform:Platform,
    public actionSheetCtrl: ActionSheetController) { }

  async presentLoading(message: any = "Cargando", maxDurationSeconds: number = 10) {
    if (!this.loader) {
      this.loader = await this.loadingController.create({
        spinner: "crescent",
        duration: maxDurationSeconds * 1000,
        //message: message,
        //translucent: true,
        //cssClass: 'custom-loading',
        //backdropDismiss: true,
      });
     
    
    }
   
    return await this.loader.present();

  }

  async dismissLoader() {
    if (this.loader) {

      await this.loader.dismiss();
      
      this.loader = null;
        /*.then(() => {
          this.loader = null;
        })
        .catch(e => console.log(e));*/

    }
    return true;
  }

  async showToast(message: string, position: any = "bottom") {
    let toast = await this.toastCtrl.create({
      message: message,
      position: position,
      duration: 4000,
    });
    toast.present();
  }

  /*
    resolveNativePath(path: any) {
      return this.filePath.resolveNativePath(path);
    }
  
  takePicture(destinationType?: "FILE_URI" | "DATA_URL" |"NATIVE_PATH", options?:any) {
      return  new Promise( async (resolve) => {
        let actionSheet = await this.actionSheetCtrl.create({
          header: 'Subir imagen',
          buttons: [{
                icon: 'image',
                text: 'Galería',
                handler: () => {
                    return resolve(this.takePictureDevice(this.camera.PictureSourceType.PHOTOLIBRARY));
                }
          },
          {
                icon: 'camera',
                text: 'Cámara',
                handler: () => {
                  return resolve(this.takePictureDevice(this.camera.PictureSourceType.CAMERA));
                }
          },
          {
                icon: 'close',
                text: 'Cancelar',
                role: 'cancel',
                handler: () => {
                  return resolve(false)
                }
          }]
        });
        actionSheet.present();
      });
    }
  
  takePictureDevice(sourceType: any = this.camera.PictureSourceType.PHOTOLIBRARY, destinationType?: "FILE_URI" | "DATA_URL" | "NATIVE_PATH", options?: any) {
      let fileName;
      let cameraOptions: CameraOptions = {
        quality: 50,
        //targetWidth: 200,
        destinationType: this.camera.DestinationType.FILE_URI,
        encodingType: this.camera.EncodingType.JPEG,
        mediaType: this.camera.MediaType.PICTURE,
        sourceType: sourceType,
        targetWidth: 850,
        targetHeight: 850,
        correctOrientation: true,
        saveToPhotoAlbum: false,
      }
      if (options) {
        for (var i in options) {
          cameraOptions[i] = options[i];
        }
      }
      if (destinationType == 'DATA_URL') {
        cameraOptions.destinationType = this.camera.DestinationType.DATA_URL;
      }
      return this.camera.getPicture(cameraOptions).then((imageData) => {
        // imageData is either a base64 encoded string or a file URI
        if (destinationType == 'NATIVE_PATH') {
          return this.resolveNativePath(imageData);
        } else if (destinationType == "DATA_URL") {
          return `data:image/jpeg;base64,${imageData}`;
        }
        else if (this.platform.is('android')) { //&& sourceType === this.camera.PictureSourceType.PHOTOLIBRARY) {
          return 'file://' + imageData;
        }

        return imageData;
      }, (err) => {
        // Handle error

        this.showToast(err);
        return false;
      })
        .then((imageURI: any) => {
          if(!imageURI) {
            throw 'No selecciono imagen';
          }
          return this.crop.crop(imageURI, {
            quality: 50
          });
        })
        .then((newPath) => {
          if(!newPath) {
            throw 'No selecciono imagen';
          }
          if (this.platform.is('android')) { //&& sourceType === this.camera.PictureSourceType.PHOTOLIBRARY) {
            return this.filePath.resolveNativePath(newPath);
          }

          return newPath;
        })
        .then((imagePath: any) => {

          if(!imagePath) {
            throw 'No selecciono imagen';
          }
          var copyPath = imagePath;
          var splitPath = copyPath.split('/');
          var imageName = splitPath[splitPath.length - 1];
          fileName = imageName;
          var filePath = imagePath.split(imageName)[0];
          return this.file.readAsDataURL(filePath, imageName);
        })
        .then((base64Data: any) => {

          if(!base64Data) {
            throw 'No hay imagen';
          }
          return { url: base64Data, name: fileName || "image.jpg", isNew: true }
        })
        ;
    }
  */
}
